import React from "react";
import { graphql } from "gatsby"

import Metadata from '../components/Metadata';
import PageLayout from "../layouts/Page";
import Hero from "../components/Hero";

import "./ebook-page.scss";

export const query = graphql`
  query EbookThankYouQuery($id: String!) {
    ebook: sanityEbook(_id: { eq: $id }) {
      _id
      ebookTitle
      _rawDescription
      pdfFile {
        asset {
          url
        }
      }
      seo {
        metaDescription
        metaTitle
        ogImage {
          asset {
            url
          }
        }
      }
    }
  }
`;

const EbookPage = props => {

  const {ebook} = props.data

  return (
    <PageLayout>
      <Metadata 
        title={`${ebook.ebookTitle} eBook`} 
        description={ebook.seo?.metaDescription} 
        image={ebook.seo?.ogImage?.asset.url || null}
      >
        <meta name="robots" content="noindex" />
      </Metadata>
      <Hero className="EbookThankYouHero">
        <Hero.Title>
          Thank you!
        </Hero.Title>
        <Hero.Text>
          <p>Click the button below to download your copy of <strong>{ebook.ebookTitle}</strong></p>
          <p>You will also receive an email with a download link to reference in the future.</p>
          <p>Enjoy the eBook!</p>
        </Hero.Text>
        <Hero.CallToActions callToActions={[{url: ebook.pdfFile.asset.url, _key: 1, label: "Download PDF"}]} />
      </Hero>
    </PageLayout>
  );
}

export default EbookPage;
